import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import styled from "styled-components"
import SmallHeroSection from "../components/SmallHeroSection"
import DecorativeSection from "../components/DecorativeSection"
import { graphql } from "gatsby"
import DietLayout from "../components/DietLayout"

export const query = graphql`
  {
    file(name: { eq: "diet1" }) {
      base
      publicURL
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
  }
`

var planWege = {
  title: "Wege",
  menu: [
    {
      name: "Śniadanie",
      hour: "7:00",
      description: "Bananowe placuszki-serniczki z syropem klonowym",
    },
    {
      name: "Drugie Śniadanie",
      hour: "10:00",
      description: "Sałatka z melonem i selerem naciowym",
    },
    {
      name: "Obiad",
      hour: "13:00",
      description: "Wege lasagne ze szpinakiem suszonymi pomidorami i sałatką",
    },
    {
      name: "Podwieczorek",
      hour: "17:00",
      description: "Zupa dyniowa z suszonym jabłkiem",
    },
    {
      name: "Kolacja",
      hour: "19:30",
      description: "Kotleciki z ricottą z warzywami",
    },
  ],
}

const PlanWegePage = ({ data }) => {
  console.log(data)
  return (
    <Layout>
      <Seo title="Plan Wege" />
      <SmallHeroSection />
      <DecorativeSection />

      <DietLayout diet={planWege} image={data.file}>
        Jest to dieta wykluczająca każdy rodzaj mięsa. Jadłospis jest
        zbilansowany, różnorodny i zawiera m.in. takie produkty jak sery, mleko,
        produkty mleczne, jaja, pestki, nasiona, bakalie, owoce, warzywa, a
        także szeroką gamę produktów zbożowych. Jeśli jesteś wegetarianinem ten
        wybór będzie dla Ciebie najlepszy!
      </DietLayout>
    </Layout>
  )
}

export default PlanWegePage
